import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import {
  type PropsWithChildren,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerTitle,
} from "../../../../../components/drawer";
import { LoadingSpinner } from "../../../../../components/loading";

import { homeState } from "../../../../../state/home";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs, { Dayjs } from "dayjs";

import { useGetLead, useGetProfile } from "../../../../../services";

import { useCreateContract } from "../../../../../services/organtization/create-contract";
import { toast } from "sonner";
import { LeadRowCard } from "../card/LeadRowCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { UserProfile } from "../../../../../client";
import { useUpdateContract } from "../../../../../services/organtization/contract/update-contract";
import { useGetMatch } from "../../../../../services/organtization/get-match";
import { NewMemberCard } from "../../../members/components/NewMemberCard";
import { getImageUrl } from "../../../../../utils/image";
import { SkillStackDrawerHeader } from "./SkillStackDrawerHeader";

export const SkillStackDrawer = ({ children }: PropsWithChildren) => {
  const { organizationId } = useParams();
  if (!organizationId) throw new Error("Organization ID is required");

  const {
    data: skillMatchData,
    isLoading,
    error: skillMatchError,
  } = useGetMatch(
    {
      organizationId: organizationId,
      skills: homeState.skillStackSkillName
        ? [homeState.skillStackSkillName]
        : [],
    },
    { enabled: !!homeState.skillStackSkillName },
  );

  const toastId = useRef<string | number>();

  const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>(
    null,
  );
  const [currentProfileName, setCurrentProfileName] = useState<string>("");

  const [selectedEndDate, setSelectedEndDate] = useState<Dayjs | null>(null);

  const { skillStackSkillName } = useSnapshot(homeState);

  const handleDrawerClose = () => {
    homeState.skillStackSkillName = null;
  };

  return (
    <Drawer
      direction="right"
      open={!!homeState.skillStackSkillName}
      onClose={handleDrawerClose}
      shouldScaleBackground={false}
    >
      {children}

      {isLoading ? (
        <Loading />
      ) : (
        <DrawerContent
          className="min-w-[60%] max-w-[60%] flex flex-col"
          direction="right"
        >
          <VisuallyHidden.Root>
            <DrawerTitle>Edit link</DrawerTitle>
            <DrawerDescription>
              Edit the link for the profile version
            </DrawerDescription>
          </VisuallyHidden.Root>

          <SkillStackDrawerHeader
            skillName={homeState.skillStackSkillName ?? ""}
            handleClose={handleDrawerClose}
          />

          <div className="flex flex-1 p-10">
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-3 md:gap-4 md:gap-y-1 lg:gap-3">
              {skillMatchData &&
                skillMatchData.map((profileSkillMatch) => (
                  <Link
                    key={profileSkillMatch.profileId}
                    to={`/dash/${organizationId}/members/${profileSkillMatch.profileId}`}
                  >
                    <NewMemberCard
                      profileName={
                        profileSkillMatch.profileName
                          ? profileSkillMatch.profileName
                          : ""
                      }
                      profilePhotoId={profileSkillMatch.profilePhotoId}
                      organizationLogo={
                        homeState.organizationIconName
                          ? getImageUrl(homeState.organizationIconName)
                          : undefined
                      }
                    />
                  </Link>
                ))}
            </div>
          </div>
        </DrawerContent>
      )}
    </Drawer>
  );
};

const Loading = () => (
  <div className="flex justify-center items-center h-full w-full">
    <LoadingSpinner size="small" />
  </div>
);
